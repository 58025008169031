import React from 'react';
import PropTypes from 'prop-types';
import { numeric } from 'helpers/i18n';
import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';
import ControlButton from 'components/home/patientDashboard/ControlButton';
import { getMenuText } from 'components/menu/SettingsMenu';
import { menuLinkStyle } from 'components/menu/MenuBar';
import {
  Panel, Link, Fragment,
} from 'components/utils';


const createComponentStyles = ({ version }) => numeric(version, {
  other: {
    styles: {
      getContainer: ({ link }) => (link ? Fragment : Panel),
    },
  },
  v2: {
    styles: {
      getContainer: ({ link }) => (link ? Fragment : ControlButton),
    },
  },
});

const ReportsButtonLink = ({ theme: { styles: { getContainer } }, link }) => {
  const Container = getContainer({ link });
  return (
    <Panel align="center">
      <Link
        key="reports"
        to="/reports"
        className={menuLinkStyle}
      >
        <Container>
          {getMenuText('reports')}
        </Container>
      </Link>
    </Panel>
  );
};

ReportsButtonLink.propTypes = {
  theme: ThemeConfig.isRequired,
  link: PropTypes.bool,
};

ReportsButtonLink.defaultProps = {
  link: false,
};

export default withThemedComponents(createComponentStyles)(ReportsButtonLink);
