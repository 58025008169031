import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { uniqWith } from 'lodash';
import { css } from 'emotion';
import { mq } from 'components/utils/Responsive';
import md from 'helpers/markdown';
import { createTranslator, numeric } from 'helpers/i18n';
import Session from 'models/Session';
import Referral from 'models/Referral';

import {
  ButtonBar,
  TextField,
  Button,
  Styles,
  Divider,
  Fragment,
} from 'components/utils';

import SendToManyProvidersLink from 'components/messages/sendtomany/SendToManyProvidersLink';
import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';

const { Secondary } = Styles;

const tr = createTranslator({
  form: {
    text: {
      placeholder: 'Your message here...',
      help: 'You can also press Control+Enter to send your message',
    },
    submit: 'Send',
  },
  sendToManyLinkText: 'Send to many',
  sendingAs: name => `**Sending As:** ${name}`,
});

const SplitPanel = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  > div.primarySection {
    flex-basis: 45%;
  };
  > ${Secondary} {
    flex-basis: 55%;
  };
`;

const Organization = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
});

const createComponentStyles = ({ version, theme: { colors } }) => numeric(version, {
  other: {
    styles: {
      MessageFormContainer: Fragment,
      lineCount: 3,
    },
  },
  v2: {
    styles: {
      MessageFormContainer: styled.div`
        ${mq({ marginTop: ['30px', '30px', '0'] })};
      `,
      buttonStyles: css`
        padding: 5px 20px;
      `,
      buttonColor: colors.SENDING_PRIMARY_BUTTON_FILL,
      lineCount: 2,
    },
  },
});

class SendMessageForm extends React.Component {
  static propTypes = {
    onMessageSent: PropTypes.func,
    recipient: Organization,
    sender: Organization,
    sendMessage: PropTypes.func.isRequired,
    session: Session.isRequired,
    referral: Referral.isRequired,
    theme: ThemeConfig.isRequired,
  }

  static defaultProps = {
    onMessageSent: undefined,
    recipient: undefined,
    sender: undefined,
  }

  constructor(props) {
    super(props);
    this.state = { text: '' };
  }

  handleChange = (text) => { this.setState({ text }); }

  handleInput = (e) => {
    if (e.ctrlKey && e.keyCode === 13) {
      this.handleSubmit();
    }
  }

  handleMessageSent = () => {
    this.setState({ text: '' });
    if (this.props.onMessageSent) {
      this.props.onMessageSent();
    }
  }

  handleSubmit = () => {
    this.props.sendMessage({
      organization: {
        ...this.props.recipient,
      },
      message_content: {
        text: this.state.text,
        organization: {
          ...this.props.sender,
        },
        user: {
          id: this.props.session.user.id,
        },
      },
    }).then(this.handleMessageSent);
  }

  render() {
    let orgs = (this.props.session.organizations || [])
      .filter(o => !!o);
    orgs = uniqWith(orgs, (a, b) => (a.id === b.id && a.organization_type === b.organization_type));
    const {
      styles: {
        buttonStyles, buttonColor, MessageFormContainer, lineCount,
      },
    } = this.props.theme;
    return (
      <div>
        <Divider margin={10} />
        <MessageFormContainer>
          {orgs.length > 1 && (
            <Secondary css={{ textAlign: 'left' }}>{md(tr('sendingAs', this.props.sender.name))}</Secondary>
          )}
          <TextField
            disabled={!this.props.recipient}
            value={this.state.text}
            onChange={this.handleChange}
            onKeyDown={this.handleInput}
            lines={lineCount}
            placeholder={tr('form.text.placeholder')}
          />
          <SplitPanel>
            <Secondary>
              {tr('form.text.help')}
            </Secondary>
            <div className="primarySection">
              <ButtonBar spacing={8} align="right">
                <SendToManyProvidersLink
                  onMessageSent={this.handleMessageSent}
                  disabled={!this.props.recipient || !this.state.text}
                  label={tr('sendToManyLinkText')}
                  referral={this.props.referral}
                  text={this.state.text}
                  selection={(this.props.recipient && this.props.recipient.organization_type === 'Provider')
                    ? [this.props.recipient.id] : undefined}
                />
                <Button
                  small
                  className={buttonStyles}
                  disabled={!this.props.recipient || !this.state.text}
                  onClick={this.handleSubmit}
                  color={buttonColor}
                >
                  {tr('form.submit')}
                </Button>
              </ButtonBar>
            </div>
          </SplitPanel>
        </MessageFormContainer>
      </div>
    );
  }
}

export default withThemedComponents(createComponentStyles)(SendMessageForm);
