/* eslint-disable import/prefer-default-export, camelcase */
import qs from 'query-string';

import apiDirector from 'helpers/ApiDirector';
import { createTranslator } from 'helpers/i18n';
import { sortAndMapProviders } from 'components/utils/AidinScore';

const sanitizeHtml = require('sanitize-html');

const tr = createTranslator({
  subjects: {
    patientChoice: id => `Patient Options for Referral ${id}`,
    attachments: id => `Attachments for Referral ${id}`,
  },
  filenames: {
    referral: id => `Aidin Referral ${id}`,
    patientChoice: id => `Patient Choice PDF ${id}`,
    document: id => `Aidin Inbox Document ${id}`,
  },
});

/* This fattens the referral with search and system data, so that it can be sent to
   any simple service for rendering */
const shapeSentReferrals = (
  searchResults,
  sent_referrals,
  system,
) => sent_referrals.map((sr) => {
  const result = searchResults.find(r => r.id === sr.provider.id) || sr.provider;
  return {
    ...sr,
    provider: {
      ...result,
      score: result.score,
      state: system.states.find(state => state.id === sr.provider.state_id),
    },
    score: result.score,
  };
}).sort((pl, pr) => {
  const plIdx = searchResults.findIndex(r => r.id === pl.provider.id);
  const prIdx = searchResults.findIndex(r => r.id === pr.provider.id);
  return plIdx - prIdx;
});

const trimSystemData = ({ badges = [] } = {}) => ({
  badges,
});

export const createPatientChoicePDFObject = (referral, {
  listProviderSearchResults,
  system,
}) => listProviderSearchResults(referral.id)
  .then(({ results, search, sent_referrals }) => [
    search,
    shapeSentReferrals(results, sent_referrals, system),
  ])
  .then(([search, sent_referrals]) => (
    [search, sortAndMapProviders(sent_referrals, { presorted: true })]
  ))
  .then(([
    {
      active_providers,
      provider_search_results,
      ...search
    },
    sent_referrals,
  ]) => ({
    ...referral,
    provider_search: search,
    sent_referrals,
    referralRoleOrganization: referral.referralRoleOrganization
      ? {
        id: referral.referralRoleOrganization.id,
        name: referral.referralRoleOrganization.name,
        providerTypes: referral.referralRoleOrganization.providerTypes,
      }
      : {},
    referralRoles: [],
  }));

const getEmailSendingOptions = (subject, template, emails = {}) => {
  const { to: items = [], from } = emails;
  return items.map((to) => ({
    subject,
    template,
    params: { to, from },
  }));
};

export const assemblePatientChoicePDF = (referral, props, emails = {}) => (
  createPatientChoicePDFObject(referral, props)
    .then(printable => Promise.resolve(apiDirector.validateFetch(`/api/referrals/${referral.id}/assemble_pdf`, {
      method: 'POST',
      body: JSON.stringify({
        assemble: [
          {
            action: 'generate',
            script: 'patientChoicePDF',
            data: {
              referral: printable,
              system: trimSystemData(props.system || {}),
              locale: props.printLocale,
            },
          },
        ],
        name: tr('filenames.patientChoice', referral.id),
        send: getEmailSendingOptions(
          tr('subjects.patientChoice', referral.id),
          'sender-email-patient-options-next',
          emails,
        ),
      }),
    })))
);

const assemblePDFs = (referral, assemble, emails = {}, props = {}) => apiDirector
  .validateFetch(`/api/referrals/${referral.id}/assemble_pdf`, {
    method: 'POST',
    body: JSON.stringify({
      assemble,
      name: tr('filenames.referral', referral.id),
      send: getEmailSendingOptions(
        tr('subjects.attachments', referral.id),
        'share-attachments-between-users-link-next',
        emails,
      ),
      zip: props.zip,
    }),
  });

/**
 * Maps an attachment to an assembler instruction item. Generated
 * attachments will look for data in the data map, with the
 * attachment id as the key.
 */
const createAssemblyItemMapper = (data = {}) => (attachment) => {
  const generateData = () => {
    if (typeof data[attachment.id] === 'function') {
      return data[attachment.id](attachment);
    }
    return data[attachment.id];
  };
  if (attachment.id === 'generated-list') {
    return {
      action: 'generate',
      script: 'patientChoicePDF',
      data: generateData(),
      saveAs: attachment.saveAs,
    };
  }
  if (attachment.id === 'generated-contacted') {
    return {
      action: 'generate',
      script: 'contactedProvidersPDF',
      data: generateData(),
      saveAs: attachment.saveAs,
    };
  }
  if (attachment.id === 'generated-dissemination') {
    return {
      action: 'generate',
      script: 'patientDisseminationPDF',
      data: generateData(),
      saveAs: attachment.saveAs,
    };
  }
  if (attachment.id === 'generated-cover-sheet') {
    return {
      action: 'cover-sheet',
      data: attachment.referralRole === 'receiving'
        ? { attachable_type: 'SentReferral', attachable_id: attachment.referralResponse.id }
        : undefined,
      saveAs: attachment.saveAs,
    };
  }
  if (attachment.id === 'generated-overview') {
    const html = sanitizeHtml(document.getElementById('scrapeableDetails').innerHTML, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(['span', 'h2', 'img']),
      allowedAttributes: {
        '*': ['class', 'role', 'id'],
        img: ['data-printable', 'src', 'alt', 'class'],
      },
      allowedSchemes: ['data'],
      exclusiveFilter: ({ tag, attribs: attrs } = {}) => {
        switch (tag) {
          case 'img':
            return attrs['data-printable'] !== 'pdf';
          default:
            return false;
        }
      },
    }).replace(/css-[^-]*?-/g, '')
      .replace(/class="(.*?)"/g, (match, ref) => (`class="${ref.replace(/-/g, ' ')}"`));
    return {
      action: 'html',
      html: `<html>
        <head>
          <base href='${window.location.protocol}//${window.location.hostname}:${window.location.port}' />
        </head>
        <body>${html}</body>
      </html>`,
      css: 'referral_overview_html_to_pdf.css',
      saveAs: attachment.saveAs,
    };
  }
  if (attachment.id === 'generated-outbound-cover-sheet') {
    return {
      action: 'generate',
      script: 'outboundDocumentPDF',
      data: {
        organization: attachment.organization,
        document: attachment.document,
      },
      saveAs: attachment.saveAs,
    };
  }
  if (attachment.id === 'generated-patient-demographic-cover-sheet') {
    return {
      action: 'generate',
      script: 'respondViaFaxPatientDemographicsPDF',
      data: {
        referral_id: attachment.document.id,
      },
      saveAs: attachment.saveAs,
    };
  }
  if (attachment.dataSource === 'patient_reports') {
    return {
      action: 'patient_report',
      attachment_id: attachment.id,
      saveAs: attachment.saveAs,
    };
  }
  return {
    action: 'fetch',
    attachment_id: attachment.id,
    saveAs: attachment.saveAs,
  };
};

/**
 * Build out list of instructions to send to the server for processing.
 * in order to construct a PDF from a referral attachment.
 *
 * Generally, you'll want to use generateAttachmentsPDF to send this to
 * the save endpoint, but if you just need the instructions and plan to
 * later send to a custom endpoint, use this instead.
 */
export const buildAttachmentsPDFInstructions = (referral, attachmentsInput, props) => {
  const attachments = attachmentsInput.map((item) => {
    if (typeof item.id === 'string') {
      const [id, query] = item.id.split('?');
      return {
        ...qs.parse(query),
        ...item,
        id,
      };
    }
    return item;
  });
  const setup = [];
  if (attachments.some(a => a.id === 'generated-list')) {
    setup.push(() => createPatientChoicePDFObject(referral, props).then(printable => ({
      'generated-list': printable,
    })));
  }
  if (attachments.some(a => a.id === 'generated-dissemination')) {
    setup.push(() => props.generateSearchedProvidersByType().then(printable => ({
      'generated-dissemination': printable,
    })));
  }
  if (attachments.some(a => a.id === 'generated-overview')) {
    setup.push(() => props.prepareDocuments(referral, attachments).then(() => ({})));
  }

  const setupPrerequisites = () => setup.reduce((current, next) => (
    current.then(set => next().then(payload => ({ ...set, ...payload })))
  ), Promise.resolve({}));

  return setupPrerequisites().then((content) => (
    attachments.map(createAssemblyItemMapper({
      'generated-list': attachment => ({
        referral: content['generated-list'],
        system: props.system,
        locale: attachment.printLocale || props.printLocale,
        saveAs: attachment.saveAs,
      }),
      'generated-dissemination': () => {
        const sortedProviders = (info, type) => {
          const allProviders = content['generated-dissemination'];
          const manualProviders = allProviders.manualResults;
          const newtworkProviders = allProviders.networkResults;
          const nonMatchingNetworkProviders = allProviders.nonMatchingNetwork;
          const organicProviders = allProviders.organicResults;
          const sentProviders = allProviders.sent;
          if (type === 'sent') {
            return sentProviders.map(p => p?.id);
          }
          return sentProviders.concat(manualProviders)
            .concat(newtworkProviders)
            .concat(nonMatchingNetworkProviders)
            .concat(organicProviders)
            .map(p => p?.id);
        };
        return {
          searchedProviders: sortedProviders(props, 'searched'),
          sentProviders: sortedProviders(props, 'sent'),
          referral_id: props.referral?.id,
        };
      },
    }))
  )).catch(() => { throw new Error('error.generate'); });
};

/**
 * Generates a combined PDF for the attachments given.
 * props should contain system and listProviderSearchResults if
 * one of the attachments could be the patient choice PDF.
 */
export const generateAttachmentsPDF = (referral, attachmentsInput, props, emails = {}) => (
  buildAttachmentsPDFInstructions(referral, attachmentsInput, props)
    .then(instructions => assemblePDFs(referral, instructions, emails, props))
    .catch(() => { throw new Error('error.generate'); })
);

/**
 * Generates a combined PDF for a document. Automatically
 * affixes the cover sheet.
 */
export const generateOutboundDocumentPDF = (document) => {
  const {
    hospital: organization,
    patient,
    attachments,
  } = document;

  const coverSheets = [
    { id: 'generated-outbound-cover-sheet', organization, document },
  ];
  if (patient) {
    coverSheets.push({
      id: 'generated-patient-demographic-cover-sheet',
      document,
    });
  }
  const assemble = coverSheets
    .concat(attachments.map(({ id }) => ({ id })))
    .map(createAssemblyItemMapper());

  return apiDirector.validateFetch(`/api/inbox/outbound/${document.id}/assemble_pdf`, {
    method: 'POST',
    body: JSON.stringify({
      assemble,
      name: tr('filenames.document', document.id),
    }),
  });
};
