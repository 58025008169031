import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { css } from 'emotion';
import { mq } from 'components/utils/Responsive';
import { getHospitalDischargeDate } from 'components/referrals/HospitalDischargeDate';
import { createTranslator, numeric } from 'helpers/i18n';
import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';
import ConversationContextMenu from 'components/messages/ConversationContextMenu';
import { Panel, Styles } from 'components/utils';
import messageText from './translations';

const { Secondary } = Styles;

const tr = createTranslator(messageText);

const MenuContainer = styled.div`
  position: absolute;
  right: 0;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const createComponentStyles = ({ version, theme: { colors } }) => numeric(version, {
  other: {
    styles: {},
  },
  v2: {
    styles: {
      panelStyles: css`
        background-color: ${colors.MEDIUM_DARK_GRAY_FILL};
        border-radius: 5px;
        ${mq({ padding: ['10px 40px', '10px 40px', '10px 10px'] })};
      `,
    },
  },
});

const ReferralInfo = ({
  referral,
  thread,
  close,
  theme: {
    styles: {
      panelStyles,
    },
  },
}) => (
  <Container>
    <Panel spacing={15} orientation="horizontal" className={panelStyles}>
      <Secondary>{tr('referral.reference_number', referral.id)}</Secondary>
      {referral.level_of_care?.length && [
        <Secondary key={1}>|</Secondary>,
        <Secondary key={2}>{referral.level_of_care.join(', ')}</Secondary>,
      ]}
      {getHospitalDischargeDate(referral).label && (
        <Panel spacing={15} orientation="horizontal">
          <Secondary>|</Secondary>
          <Secondary>{getHospitalDischargeDate(referral).label}</Secondary>
        </Panel>
      )}
    </Panel>
    <MenuContainer>
      <ConversationContextMenu thread={thread} closePopup={close} />
    </MenuContainer>
  </Container>
);

ReferralInfo.propTypes = {
  referral: PropTypes.shape({
    id: PropTypes.number.isRequired,
    level_of_care: PropTypes.array,
    hospital_discharge_date: PropTypes.string,
  }).isRequired,
  thread: PropTypes.shape({
    referral: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  close: PropTypes.func.isRequired,
  theme: ThemeConfig.isRequired,
};

export default withThemedComponents(createComponentStyles)(ReferralInfo);
