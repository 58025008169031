import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { groupBy, isEqual } from 'lodash';
import styled from 'react-emotion';

import { createTranslator, numeric } from 'helpers/i18n';
import { applyTestIdentifier } from 'helpers/development';
import { getDisplayName } from 'models/Provider';

import AutocompleteField, { PropertyFilter } from 'components/utils/AutocompleteField';
import Button from 'components/utils/Button';
import CheckboxField from 'components/utils/CheckboxField';
import Divider from 'components/utils/Divider';
import Popup from 'components/utils/Popup';
import { mq } from 'components/utils/Responsive';
import SelectField from 'components/utils/SelectField';
import Spinner from 'components/utils/Spinner';
import * as styles from 'components/utils/Styles';

import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';

import ProviderSearchPopupContent from 'components/referrals/search/ProviderSearchPopupContent';

const { Secondary } = styles;

const tr = createTranslator({
  selectAll: 'Select All',
  showAll: 'show all',
  hideAll: 'hide all',
});

const createComponentStyles = ({ version, theme: { colors } }) => numeric(version, {
  other: {
    styles: {
      Title: styled.h4(),
      TriggerContainer: 'span',
      SelectLabel: () => 'div',
      SubTitle: styled.h4(),
      LinkText: styled.span(),
      HelpText: styled.div`
          margin-top:10px;
        `,
      SecondaryItem: styled(Secondary),
      POPUP_OFFSET: 0,
      POPUP_POSITION: 'bottom right',
      FULL_WIDTH_TRIGGER_OFFSET_X: -0.5,
    },
  },
  v2: {
    styles: {
      Title: styled.div`
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: ${colors.BLACK};
          margin-bottom: 20px;
        `,
      SubTitle: styled.div`
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: ${colors.BLACK};
        `,
      LinkText: styled.div`
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.33;
          letter-spacing: normal;
          text-align: right;
      `,
      customSelect: (highlight) => (css(mq({
        '.Select-control': {
          border: 'none !important',
        },
        '.Select.is-disabled > .Select-control': {
          cursor: 'pointer',
          boxShadow: 'none',
          borderRadius: '5px',
          backgroundColor: [highlight ? colors.RECEIVING_DARKER_FILL : colors.MEDIUM_DARK_GRAY_FILL, colors.WHITE, colors.WHITE],
          '.Select-arrow': {
            borderWidth: '8px 5px 0.5px',
            color: highlight ? colors.WHITE : colors.BLACK,
            opacity: 1,
            borderColor: `${highlight ? colors.WHITE : colors.BLACK} transparent transparent`,
          },
          '.Select-value': {
            paddingLeft: 0,
            color: colors.WHITE,
            '.Select-value-label': {
              color: highlight && `${colors.WHITE} !important`,
            },
          },
          '.Select-arrow-zone': {
            opacity: 1,
          },
          '.Select-input:focus': {
            position: 'unset',
          },
        },
      }))),
      TriggerContainer: styled.div(({
        active, highlight, width,
      }) => mq({
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: [active ? colors.BODY_FILL : (highlight ? colors.RECEIVING_DARKER_FILL : colors.MEDIUM_DARK_GRAY_FILL), colors.WHITE, colors.WHITE],
        width: [`${width}`, 'auto', 'auto'],
        height: 'auto',
        padding: '9.7px 4.2px 8.6px 13.1px',
        borderRadius: '5px',
        cursor: 'pointer',
        border: `1px solid ${active ? colors.GRAY_FILL : colors.MEDIUM_DARK_GRAY_FILL}`,
        zIndex: active && 100,
        '.Select.is-disabled > .Select-control': {
          backgroundColor: colors.BODY_FILL,
          outline: 'none',
        },
      })),
      SelectLabel: (highlight) => (styled.div`
          height: 12px;
          margin: 0 0 6px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: left;
          color: ${highlight ? colors.WHITE : colors.GRAY_FILL};
        `),
      HelpText: styled.div`
          margin-top:12px;
        `,
      SecondaryItem: styled(Secondary)`
          font-weight: 600;
          color: ${colors.BLACK};
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: right;
          opacity: 1;
        `,
      POPUP_OFFSET: 8,
      POPUP_POSITION: 'bottom left',
      FULL_WIDTH_TRIGGER_OFFSET_X: -0.5,
    },
  },
});

const ListItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => (props.selected ? '#eeeeee' : 'inherit')};
  padding: 7px 0px 6px;
  width: 100%;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const ListItemContent = styled.div`
  padding: 0px 5px;
`;

const ListOptions = styled.div`
  display: flex;
`;

const HideUnselectedButtonStyle = css`
  width: 120px;
`;

const GroupItem = styled.div`
  margin-top: 8px;
`;

const LoadingContainer = styled.div`
  margin-top: 40px;
`;

class MultiSelectDashboardFilter extends React.Component {
  constructor(props) {
    super(props);

    const { hideUnselected, value } = props;

    this.state = {
      clickedValues: [],
    };

    let values = this.allValues();

    if (value === 'none') {
      values = [];
    } else if (value && value !== 'all') {
      values = value.toString().split(',');
    }

    this.state = {
      hideUnselected,
      loading: false,
      values,
      active: false,
      allSelectedClicked: true,
      clickedValues: [],
      calculatedTriggerPopupWidth: undefined,
    };
  }

  componentDidMount() {
    this.updateTriggerPopupWidth();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { open, value } = nextProps;
    if (this.props.disableAllSelected) {
      this.setState({ values: this.allValues(nextProps, true) });
      if (this.props.open && !open) {
        this.onClose();
      }
      return;
    }
    if (value === 'all' && !this.allSelected(nextProps)) {
      this.setState({ values: this.allValues(nextProps) });
    } else if (value === 'all' && !isEqual(this.allValues(), this.allValues(nextProps))) {
      this.setState({ values: this.allValues(nextProps) });
    } else if (value === 'none' && this.state.values.length) {
      this.setState({ values: [] });
    } else if (value && value !== 'all' && value !== 'none') {
      const all = this.allValues(nextProps, true);
      const nextValues = value.toString().split(',')
        .filter(i => all.some(k => k === i));
      this.setState({ values: nextValues.length === 0 ? all : nextValues });
    }

    if (this.props.open && !open) {
      this.onClose();
    }
  }

  allSelected = (props = this.props) => {
    if (props.disableAllSelected) {
      if (this.state.values?.length === 0 && this.state.allSelectedClicked) {
        return true;
      }
      if (this.state.values.length !== 0) {
        return false;
      }
    }
    return this.state.values.length === props.items.length;
  }

  allValues = (props = this.props, includeDefaultItems = false) => {
    const { items, defaultItems } = props;
    if (props.disableAllSelected) {
      let newItems = items.map(i => i.value);
      if (includeDefaultItems && props.value !== 'all') {
        newItems = defaultItems.map(i => i.value)
          .filter(i => (props.value?.split(',') || []).includes(i))
          .concat(newItems);
      }
      return newItems.filter(i => this.state.clickedValues?.indexOf(i) === -1);
    }
    return defaultItems.concat(items).map(i => i.value);
  }

  autocompleteSelect = (item) => {
    if (this.state.hideUnselected && this.allSelected()) {
      this.setState({ values: [] }, () => this.onSelect(true, item));
    } else if (!this.state.values.includes(item.value)) {
      this.onSelect(true, item);
    }
  }

  filterEntries = (entries) => {
    if (this.state.hideUnselected) {
      if (this.allSelected()) {
        return [];
      }

      return entries.filter(({ value }) => this.state.values.includes(value));
    }

    return entries;
  };

  focusInput = () => {
    setTimeout(() => {
      if (this.mainInput) {
        this.mainInput.field.focus();
      }
    }, 50);
  }

  updateTriggerPopupWidth = () => {
    if (this.props.fullWidth && this.trigger) {
      const paddingOffset = 3;
      const element = this.trigger.field.input.control;
      const calculatedTriggerPopupWidth = element.clientWidth - paddingOffset;
      this.setState({ calculatedTriggerPopupWidth });
    }
    return undefined;
  }

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    } else {
      this.props.onSelect({ value: this.allSelected() ? 'all' : this.state.values.sort().join(',') || 'none' });
    }
    this.setState({ active: false, clickedValues: [] });
  }

  onOpen = () => {
    const { onOpen } = this.props;
    if (onOpen) { onOpen(); }
    this.focusInput();
    this.setState({ active: true });
  }

  onSelect = (selected, item) => {
    const { value } = item;
    const afterSelect = () => {
      if (this.props.onChecked) this.props.onChecked(item);
    };
    if (selected) {
      this.setState(({ values }) => ({ values: values.concat([value]) }), afterSelect);
    } else {
      this.setState(({ values }) => ({ values: values.filter(v => v !== value) }), afterSelect);
    }
  }

  renderEntries = (items, defaultItems = []) => (
    [...defaultItems.map(entry => this.renderEntry(entry)), ...(this.props.grouped
      ? Object.entries(groupBy(items, 'group')).map(([group, entries]) => this.renderGroup(group, entries))
      : this.filterEntries(items).map(entry => this.renderEntry(entry)))]
  )

  renderEntry = entry => {
    const provider = { name: entry.label, provider_type: entry.provider_type };
    const { styles: { SecondaryItem } } = this.props.theme;

    return (
      <ListItem
        key={entry.value}
        onClick={() => this.onSelect(!this.state.values.includes(entry.value), entry)}
      >
        <ListItemContent
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.onSelect(!this.state.values.includes(entry.value), entry);
            if (this.props.disableAllSelected) {
              if (this.state.clickedValues.findIndex(v => v === entry.value) === -1) {
                this.setState(({ clickedValues }) => ({
                  allSelectedClicked: false,
                  clickedValues: [...clickedValues, entry.value],
                }));
              } else {
                this.setState(({ clickedValues }) => ({
                  allSelectedClicked: false,
                  clickedValues: clickedValues.filter(v => v !== entry.value),
                }));
              }
            }
          }}
        >
          {!this.props.hideItems && (
            <CheckboxField
              label={getDisplayName(provider)}
              value={this.state.values.includes(entry.value)}
            />
          )}
        </ListItemContent>
        {!this.props.hideSecondaryContent && (
          <ListItemContent>
            <SecondaryItem>
              {this.props.grouped
                ? entry.group.toUpperCase()
                : !this.props.renderByLabel && entry.value.toUpperCase()}
            </SecondaryItem>
          </ListItemContent>
        )}
      </ListItem>
    );
  }

  renderGroup = (group, entries) => {
    const filteredEntries = this.filterEntries(entries);
    const { styles: { SubTitle } } = this.props.theme;

    return filteredEntries.length > 0 && (
      <GroupItem key={group}>
        <SubTitle>{group}</SubTitle>
        {filteredEntries.map(entry => this.renderEntry(entry))}
      </GroupItem>
    );
  };

  renderedValue = () => {
    const { valueRenderer } = this.props;
    let renderedValues;

    if (this.props.renderByLabel) {
      renderedValues = (this.props.items.concat(this.props.defaultItems))
        .filter(({ value }) => this.state.values.includes(value))
        .map(({ label }) => label);
    } else {
      renderedValues = this.state.values;
    }

    if (this.allSelected()) {
      return typeof valueRenderer.all === 'function' ? valueRenderer.all(renderedValues) : valueRenderer.all;
    }

    return valueRenderer.other(renderedValues.sort());
  }

  selectAll = (selected) => {
    if (selected) {
      this.setState({ values: this.allValues() });
    } else {
      this.setState({ values: [] });
    }
  }

  toggleAll = () => {
    if (this.state.hideUnselected) {
      this.setState({ loading: true }, () => {
        setTimeout(() => {
          this.setState(({ hideUnselected: false }), () => { this.setState({ loading: false }); });
        }, 500);
      });
    } else {
      this.setState({ hideUnselected: true });
    }
  }

  renderCompleteField = () => {
    const {
      styles: {
        Title,
        HelpText,
      },
    } = this.props.theme;

    if (this.props.renderField) {
      return this.props.renderField(this.props, this.autocompleteSelect, Title, HelpText);
    }

    return (
      <AutocompleteField
        resetValueOnChange
        ref={(ref) => { this.mainInput = ref; }}
        labelElement={Title}
        label={this.props.label}
        placeholder={this.props.placeholder}
        help={!this.props.hideHelpText && <HelpText>{this.props.help}</HelpText>}
        items={this.props.items}
        filter={PropertyFilter('value', 'label')}
        valueRenderer={value => (value ? value.label : this.props.placeholder)}
        itemRenderer={this.props.itemRenderer}
        onSelect={item => item && this.autocompleteSelect(item)}
      />
    );
  }

  renderFragment = () => {
    const {
      styles: {
        TriggerContainer: Element,
        SelectLabel,
        customSelect,
      },
    } = this.props.theme;

    const { titleElement, noHighlight } = this.props;

    /**
     * FIXME: may need to find a different identifier since
     * title is optional.
     */
    const parameterizedTitle = this.props.title
      ? this.props.title.toLowerCase().replace(/\s/g, '-')
      : 'untitled';
    const highlight = !this.allSelected() && !this.state.active;
    const CustomElement = this.props.element || Element;
    return (
      <CustomElement
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...applyTestIdentifier(`filter-${parameterizedTitle}`)}
        active={this.state.active}
        highlight={highlight}
        width={this.props.width}
      >
        <SelectField
          ref={(ref) => { this.trigger = ref; }}
          highlight={noHighlight ? false : !this.allSelected()}
          items={[]}
          labelElement={titleElement || SelectLabel(highlight)}
          label={this.props.title}
          empty=""
          value={{ label: this.renderedValue() }}
          className={(customSelect && !this.props.element) ? customSelect(highlight) : undefined}
          disabled={!!customSelect && !this.props.element}
        />
      </CustomElement>
    );
  };

  render() {
    const {
      styles: {
        Title,
        LinkText,
        FULL_WIDTH_TRIGGER_OFFSET_X,
        POPUP_OFFSET,
        POPUP_POSITION,
      },
    } = this.props.theme;

    return (
      <Popup
        maxHeight={650}
        minHeight={this.props.minHeight}
        position={POPUP_POSITION}
        onClose={this.onClose}
        onOpen={this.onOpen}
        open={this.props.open}
        offsetX={(this.props.fullWidth && this.state.calculatedTriggerPopupWidth)
          ? FULL_WIDTH_TRIGGER_OFFSET_X : undefined}
        offsetY={POPUP_OFFSET}
        trigger={this.renderFragment()}
        disableScrolling={this.props.disableScrolling}
        width={this.state.calculatedTriggerPopupWidth}
      >
        <ProviderSearchPopupContent>
          {this.props.hideAutocomplete
            ? <Title>{this.props.label}:</Title>
            : (
              <>
                {this.renderCompleteField()}
                {!this.props.hideDivider && <Divider />}
              </>
            )}
          <div>
            {!this.props.disableAllSelected && (
              <ListOptions>
                <ListItem onClick={() => this.selectAll(!this.allSelected())}>
                  <ListItemContent
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.selectAll(!this.allSelected());
                      this.setState(prevState => ({
                        allSelectedClicked: !prevState.allSelectedClicked,
                      }));
                    }}
                  >
                    <CheckboxField
                      label={tr('selectAll')}
                      value={this.allSelected()}
                    />
                  </ListItemContent>
                </ListItem>
                {this.props.hideUnselected && (
                  <Button
                    small
                    link
                    disabled={this.state.loading}
                    className={HideUnselectedButtonStyle}
                    onClick={this.toggleAll}
                  >
                    <LinkText>{tr(this.state.hideUnselected ? 'showAll' : 'hideAll')}</LinkText>
                  </Button>
                )}
              </ListOptions>
            )}
            {this.state.loading
              ? (
                <LoadingContainer>
                  <Spinner />
                </LoadingContainer>
              ) : this.renderEntries(this.props.items, this.props.defaultItems)}
          </div>
          {!this.state.loading && this.props.renderFooterContent && this.props.renderFooterContent()}
        </ProviderSearchPopupContent>
      </Popup>
    );
  }
}

MultiSelectDashboardFilter.propTypes = {
  /** Disable the allSelected feature */
  disableAllSelected: PropTypes.bool,
  /** Show popup width as same width as trigger */
  fullWidth: PropTypes.bool,
  /** Render items grouped by optional group key */
  grouped: PropTypes.bool,
  /** Help text shown below the filter */
  help: PropTypes.string,
  /** Do not render autocomplete field */
  hideAutocomplete: PropTypes.bool,
  /** Do not render secondary content for each item in list */
  hideSecondaryContent: PropTypes.bool,
  /** Do not render unselected options by default */
  hideUnselected: PropTypes.bool,
  /** Function used to render items in the filter */
  itemRenderer: PropTypes.func,
  /** Items to display in the filter */
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  /** defaultItems to always display at the top of the filter */
  defaultItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  /** Text shown above the filter */
  label: PropTypes.string,
  /** Label element, h4 by default. Can be a string or object */
  titleElement: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
  /** disables the blue highlighting on the renderFragment */
  noHighlight: PropTypes.bool,
  /** sets selected item value */
  onChecked: PropTypes.func,
  /** Listener for when popup is opened */
  onOpen: PropTypes.func,
  /** Callback when an item is selected */
  onSelect: PropTypes.func.isRequired,
  /** Override open/close state */
  open: PropTypes.bool,
  /** Placeholder text shown in the filter */
  placeholder: PropTypes.string,
  /** Use item label instead of value in valueRenderer */
  renderByLabel: PropTypes.bool,
  /** Render a different type of AutoComplete Field */
  renderField: PropTypes.func,
  /** Provided Component theme */
  theme: ThemeConfig.isRequired,
  /** Text shown above the trigger */
  title: PropTypes.string,
  /** The selected values that should be checked */
  value: PropTypes.string,
  /** Function used to display selected items in trigger */
  valueRenderer: PropTypes.shape({
    all: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    other: PropTypes.func.isRequired,
  }).isRequired,
  /** Disable the scroll */
  disableScrolling: PropTypes.bool,
  /** Function used to close the popup */
  onClose: PropTypes.func,
  /** Hide divider */
  hideDivider: PropTypes.bool,
  /** min height */
  minHeight: PropTypes.number,
  /** Hide items */
  hideItems: PropTypes.bool,
  /** Renders footer content */
  renderFooterContent: PropTypes.func,
  /** Hide help text */
  hideHelpText: PropTypes.bool,
  /** Custom width for the trigger */
  width: PropTypes.string,
  /** Custom element to use for the select field wrapper */
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
};

MultiSelectDashboardFilter.defaultProps = {
  disableAllSelected: false,
  fullWidth: false,
  grouped: false,
  help: undefined,
  hideAutocomplete: false,
  hideSecondaryContent: false,
  hideUnselected: false,
  itemRenderer: undefined,
  items: [],
  defaultItems: [],
  label: undefined,
  titleElement: undefined,
  noHighlight: false,
  onChecked: undefined,
  onOpen: undefined,
  open: undefined,
  placeholder: undefined,
  renderByLabel: false,
  renderField: undefined,
  title: undefined,
  value: undefined,
  disableScrolling: false,
  onClose: undefined,
  hideDivider: false,
  minHeight: 300,
  hideItems: false,
  renderFooterContent: undefined,
  hideHelpText: false,
  width: undefined,
  element: undefined,
};

export default withThemedComponents(createComponentStyles)(MultiSelectDashboardFilter);
