import { numeric, createTranslator } from 'helpers/i18n';

const menus = {
  account: (context, { type } = {}) => numeric(context, {
    menu: 'My Account + Notifications',
    tab: numeric(type, {
      large: 'Account & Notifs',
      other: 'Account & Notifs',
    }),
    other: 'Admin: My Account',
  }),
  activity: 'Activity',
  aidin: 'Aidin',
  badges: 'About Badges',
  badgesCheckup: 'Badges Checkup',
  brochure: (context) => numeric(context, {
    menu: 'View my provider brochure',
    title: '',
  }),
  dashboard: 'Dashboard',
  details: 'Details',
  fax_cover_sheet_pdf: 'Fax Cover Sheet PDF',
  inbox: (context, { bound } = {}) => numeric(context, {
    title: numeric(bound, {
      inbound: 'Aidin Inbox - Inbound',
      outbound: 'Aidin Inbox - Outbound',
      other: 'Aidin Inbox',
    }),
    other: 'Aidin Inbox',
  }),
  inboxInbound: 'Inbound',
  inboxOutbound: 'Outbound',
  integrations: 'Aidin Integrations',
  printers: 'Aidin Printer',
  locations: (context, { type } = {}) => numeric(context, {
    menu: 'My Locations',
    tab: numeric(type, {
      large: 'My Locations and Networks',
      other: 'Locations',
    }),
    other: 'Admin: Locations & Networks',
  }),
  loggedInAs: 'Logged in as',
  logout: 'Log out',
  new: 'New!',
  network: (context) => numeric(context, {
    menu: 'Network preferences',
    tab: 'My Locations and Networks',
    other: 'Admin: Locations & Networks',
  }),
  overview: '',
  patient_choice_pdf: 'Patient Choice List PDF',
  patient_dissemination_pdf: 'Patient Dissemination List PDF',
  profile: (context) => numeric(context, {
    menu: 'View my profile',
    other: '',
  }),
  providers: 'Providers',
  provider_overview: 'Provider Overview',
  purchases: (context, { type } = {}) => numeric(context, {
    menu: 'My Subscriptions',
    tab: numeric(type, {
      large: 'My Subscriptions',
      other: 'Subscriptions',
    }),
  }),
  settings: (context, { type } = {}) => numeric(context, {
    menu: 'My Provider Settings',
    tab: numeric(type, {
      large: 'My Provider Settings',
      other: 'Settings',
    }),
    other: 'Admin: Provider Settings',
  }),
  referrals: 'Referrals',
  referral_overview_pdf: 'Referral Overview PDF',
  reports: (context) => numeric(context, {
    tab: 'Aidin Reporting',
    other: 'Reports',
  }),
  team: (context, { type } = {}) => numeric(context, {
    menu: 'My Team',
    tab: numeric(type, {
      large: 'My Team',
      other: 'Team',
    }),
    other: 'Admin: Team Settings',
  }),
  verifyIdentity: 'Verify your identity',
  viewOrganization: 'View My Organization',
  previewOrganization: 'View My Organization (Preview)',
};

export const STR = createTranslator(menus);

export default menus;
