import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';

import Panel from 'components/utils/Panel';
import * as colors from 'components/utils/Colors';
import * as styles from 'components/utils/Styles';
import { createTranslator } from 'helpers/i18n';

const { TIP_DEFAULT, TIP_WARNING, TIP_ERROR } = colors;
const { Label, Secondary, ignoreOnPDF } = styles;

const tr = createTranslator({
  title: 'Aidin Tip',
});

const TipContainer = styled.div`
  position: relative;
  background-color: ${props => props.color};
  padding: 11px 14px;
  a {
    color: #000000;
  }
`;

const TipTitle = styled(Label)`
  white-space: nowrap;
  line-height: 1.5;
  ${props => props.color && `color: ${props.color};`}
`;

const closeButtonContainerStyle = css`
  position: absolute;
  top: 5px;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  display: inline;
`;

const closeButtonIconStyle = css`
  color: #aaaaaa;
  font-size: 20px;
`;

/**
 * Div with light gray background for displaying Aidin tips.
 * Customizable title.
 */
export default class Tip extends React.Component {
  static propTypes = {
    /** The content of the tip, is no value is given */
    children: PropTypes.node,
    /** Additional classname to provide */
    className: PropTypes.node,
    /** True to add a close icon to the tip, false otherwise (default false) */
    closeable: PropTypes.bool,
    /** The background color of the tip */
    color: PropTypes.oneOf([TIP_DEFAULT, TIP_WARNING, TIP_ERROR, 'inherit']),
    /** Custom wrapper for value or children */
    containerElement: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /** The orientation of the panel */
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    /** The title to display for the tip, defaults to 'Aidin Tip' */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
    /** The title color of the tip */
    titleColor: PropTypes.string,
    /** Perhaps the ID or lookup key of the tip text, to lookup dynamically. */
    value: PropTypes.string,
  }

  static defaultProps = {
    children: null,
    className: null,
    closeable: false,
    color: TIP_DEFAULT,
    containerElement: undefined,
    orientation: 'vertical',
    title: undefined,
    titleColor: undefined,
    value: null,
  }

  constructor(props) {
    super(props);
    this.state = { open: true };
  }

  /**
   * TODO: Send up a message saying this tip has been closed, and don't show again?
   */
  close = () => {
    this.setState({ open: false });
  }

  lookupTipContent = key => key

  renderCloseButton = () => (
    <button type="button" className={closeButtonContainerStyle} onClick={this.close}>
      <span className={`${closeButtonIconStyle} mp-cancel`} />
    </button>
  )

  render() {
    if (!this.state.open) { return null; }
    let title = typeof this.props.title === 'string' ? `${this.props.title}:` : this.props.title;
    if (!title) { title = `${tr('title')}:`; }
    const ContainerElement = this.props.containerElement || Secondary;
    return (
      <TipContainer
        className={this.props.className ? [this.props.className, ignoreOnPDF].join(' ') : ignoreOnPDF}
        color={this.props.color || Tip.defaultProps.color}
      >
        <Panel spacing={8} orientation={this.props.orientation}>
          <TipTitle color={this.props.titleColor}>
            {title}
          </TipTitle>
          <ContainerElement>
            {this.props.value ? this.lookupTipContent(this.props.value) : this.props.children}
          </ContainerElement>
        </Panel>
        {this.props.closeable && this.renderCloseButton()}
      </TipContainer>
    );
  }
}
